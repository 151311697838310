var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/_monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"b6ae26e4c80b6f90af3678d119afe427ba073d5e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
// eslint-disable-next-line path/no-relative-imports
import clientConfig from '../../packages/shared-client/config';

clientConfig.sentry.enabled = true; // TODO: REMOVE

if (clientConfig.sentry.enabled) {
	Sentry.init({
		dsn: 'https://69ef51b1808baf5082d0762f0cb8bcf6@o4507387221573632.ingest.us.sentry.io/4507387225374720',

		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 0.2,

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,

		replaysOnErrorSampleRate: 0, // disabled

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0, // disabled

		// // You can remove this option if you're not planning to use the Sentry Session Replay feature:
		// integrations: [
		// 	Sentry.replayIntegration({
		// 		// Additional Replay configuration goes in here, for example:
		// 		maskAllText: true,
		// 		blockAllMedia: true,
		// 	}),
		// ],
	});
}
